import React from "react";
/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */

export default function Logo({ fill }) {
  return (
    // <svg viewBox="0 0 106 28" height="30px">
    //   <path d="M62.9 12h2.8v10...." />
    //   <path fill={fill} d="M25 14h-..." />
    //   <path d="M14 0C6.3..." fill="#639" />
    // </svg>
    <svg
      id="logosandtypes_com"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      height="45px"
    >
      <path d="M0 0h150v150H0V0z" fill="none" />
      <path
        fill={fill}
        d="M19 18.1v114.5h26.9V77.3c0-18.1 14.7-33 33-33 12 0 22.7 6.5 28.4 16.2-3.1-3.4-7.6-5.5-12.6-5.5-9.5 0-17.4 7.8-17.4 17.4v30.5H87v9.2h-9.7v20.7h56.1V18.1H19zm75.8 67.4c-7.3 0-13-5.9-13-13 0-7.3 5.9-13 13-13 7.3 0 13 5.7 13 13 0 7.2-6 13-13 13z"
      />
    </svg>
  );
}
